import { IsString } from 'class-validator'

import { IsStringOrStringListOrNumber } from '../decorators/is-string-or-stringlist-or-number.decorator'

export class FormResponse {
  @IsString()
  question: string

  @IsString()
  id: string

  @IsStringOrStringListOrNumber()
  answer: string | string[] | number
}
