import { Box, Divider, Flex, HStack, Text, VStack } from '@chakra-ui/react'

import { NhgdLayout } from './components/NhgdLayout'
import { NhgdSentBy } from './components/NhgdSentBy'
import { EmptyRow, Row, TitleRow } from './components/TableRow'
import { extractNhgdRadiologyFields } from './extract-nhgd-lab-form-fields'
import { bodyStyle, borders, headerStyle } from './nhg-layer-styles'

import { DATE_DISPLAY_FORMATS, formatDate } from '@/utils/human-readable'
import { PublicReferralDto } from '~shared/dtos'
import {
  NHGD_RADIOLOGY_TEST_CODES,
  NhgdClinicalRadiologyPurpose,
  NhgdCollectionMode,
  NhgdLastMenstrualPeriodReason,
  NhgdPaymentMode,
  NhgdReportMaterials,
} from '~shared/form-fields'

/** Priorities for this component is to be print-friendly */
export const NhgdRadiologyFormView = ({
  referral,
}: {
  referral: PublicReferralDto
}) => {
  const data = extractNhgdRadiologyFields(referral)

  return (
    <VStack>
      <NhgdLayout title="Radiological Investigation Form" referralId={data.id}>
        <Flex flexDir="column" alignItems="stretch" border="1px">
          <Flex>
            <Box width="66.7%" {...borders} {...headerStyle}>
              {`Patient's Information`}
            </Box>
            <Box width="33.3%" {...borders} {...headerStyle}>
              {`Referral Information`}
            </Box>
          </Flex>

          <Flex>
            <Flex flexDir="column" width="66.7%" {...borders} {...bodyStyle}>
              <Text>
                <b>{`Name: `}</b>
                {data.patient.name}
              </Text>
              <Flex>
                <Text flex={1}>
                  <b>{`NRIC / FIN: `}</b>
                  {data.patient.uin}
                </Text>
                <Text flex={1}>
                  <b>{`Date of Birth: `}</b>
                  {formatDate({
                    date: data.patient.dob,
                    format: DATE_DISPLAY_FORMATS.DATE_ONLY,
                  })}
                </Text>
              </Flex>
              <Flex>
                <Text flex={1}>
                  <b>{`Sex: `}</b>
                  {data.patient.gender}
                </Text>
                <Text flex={1}>
                  <b>{`Contact Number: `}</b>
                  {`${data.patient.phoneNumber} (HP)`}
                </Text>
              </Flex>
            </Flex>
            <NhgdSentBy data={data} width="33.3%" />
          </Flex>

          <Flex>
            <Box width="66.7%" {...borders} {...headerStyle}>
              {`Patient's History`}
            </Box>
            <Box width="33.3%" {...borders} {...headerStyle}>
              {`Please specify:`}
            </Box>
          </Flex>

          <Flex>
            <Flex
              flexDir="column"
              width="66.7%"
              justifyContent="space-between"
              {...borders}
              {...bodyStyle}
              gap={2}
            >
              <VStack alignItems="start" spacing={0}>
                <Text>
                  <b>{`Relevant History / Findings`}</b>
                </Text>
                <Text lineHeight="1rem">{data.note} </Text>
              </VStack>
            </Flex>

            <Flex
              width="33.3%"
              alignItems="flex-start"
              {...borders}
              {...bodyStyle}
              px={0}
              flexDir="column"
              h="100%"
              gap={1}
            >
              <HStack alignItems="center" flex={1} px={2}>
                <b>OR</b>
                <Flex flexDir="column">
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={data.paymentMode === NhgdPaymentMode.Patient}
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Patient pay cash</Text>
                  </HStack>
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={data.paymentMode === NhgdPaymentMode.Clinic}
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Bill Clinic</Text>
                  </HStack>
                </Flex>
              </HStack>
              <Divider borderColor="black" />

              <HStack alignItems="center" flex={1} px={2}>
                <b>OR</b>
                <Flex flexDir="column">
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={
                        data.reportMaterials === NhgdReportMaterials.Report
                      }
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Report Only</Text>
                  </HStack>
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={
                        data.reportMaterials === NhgdReportMaterials.ReportAndCd
                      }
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Report and CD</Text>
                  </HStack>
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={
                        data.reportMaterials ===
                        NhgdReportMaterials.ReportAndFilms
                      }
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Report and Films</Text>
                  </HStack>
                </Flex>
              </HStack>

              <Divider borderColor="black" />

              <HStack alignItems="center" flex={1} px={2}>
                <b>OR</b>
                <Flex flexDir="column">
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={
                        data.collectionMode === NhgdCollectionMode.Patient
                      }
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Patient to collect</Text>
                  </HStack>
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={
                        data.collectionMode === NhgdCollectionMode.Dispatch
                      }
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Dispatch to clinic</Text>
                  </HStack>
                </Flex>
              </HStack>

              <Divider borderColor="black" />

              <HStack alignItems="center" flex={1} px={2}>
                <b>OR</b>
                <Flex flexDir="column">
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      style={{ filter: 'grayscale(100%)' }}
                      checked={
                        data.purpose === NhgdClinicalRadiologyPurpose.Screening
                      }
                    />
                    <Text>Purpose: Screening</Text>
                  </HStack>
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      style={{ filter: 'grayscale(100%)' }}
                      checked={
                        data.purpose === NhgdClinicalRadiologyPurpose.Others
                      }
                    />
                    <Text>Purpose: Others</Text>
                  </HStack>
                </Flex>
              </HStack>

              <Divider borderColor="black" />

              <VStack alignItems="start" px={2}>
                <Text>
                  <b>{`Last menstrual period: `}</b>
                  {data.lmpDate}
                </Text>
                <HStack>
                  <input
                    type="checkbox"
                    readOnly
                    style={{ filter: 'grayscale(100%)' }}
                    checked={
                      data.lmpReason ===
                      NhgdLastMenstrualPeriodReason.UptNegative
                    }
                  />
                  <Text>UPT result is negative</Text>
                </HStack>
                <HStack>
                  <input
                    type="checkbox"
                    readOnly
                    style={{ filter: 'grayscale(100%)' }}
                    checked={
                      data.lmpReason ===
                      NhgdLastMenstrualPeriodReason.Declaration
                    }
                  />
                  <Text>Patient declined UPT and declared non-pregnant</Text>
                </HStack>
              </VStack>
            </Flex>
          </Flex>
        </Flex>
      </NhgdLayout>

      <NhgdLayout
        title="Radiological Investigation Form"
        referralId={data.id}
        showHeaderOnlyOnPrint
      >
        <Flex flexDir="column" alignItems="stretch" border="1px">
          <Flex
            textAlign="center"
            justifyContent="center"
            {...borders}
            {...headerStyle}
          >
            {`Type of X-ray Investigation`}
          </Flex>
          <Flex justifyContent="stretch">
            <VStack spacing={0} width="33.3%" alignItems="stretch">
              <TitleRow title="Head & Neck" />
              {NHGD_RADIOLOGY_TEST_CODES.headAndNeck.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}

              <EmptyRow />
              <TitleRow title="Upper Limbs" />

              {NHGD_RADIOLOGY_TEST_CODES.upperLimbs.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}
            </VStack>
            <VStack spacing={0} width="33.3%" alignItems="stretch">
              <TitleRow title="Lower Limbs" />
              {NHGD_RADIOLOGY_TEST_CODES.lowerLimbs.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}

              <EmptyRow />
              <TitleRow title="Ultrasound" />
              {NHGD_RADIOLOGY_TEST_CODES.ultrasound.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}

              <EmptyRow />
              <TitleRow title="Mammogram" />
              {NHGD_RADIOLOGY_TEST_CODES.mammogram.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}

              <EmptyRow />
              <TitleRow title="Bone Mineral Densitometry" />
              {NHGD_RADIOLOGY_TEST_CODES.bmd.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}

              <EmptyRow />
            </VStack>
            <VStack spacing={0} width="33.4%" alignItems="stretch">
              <TitleRow title="Trunk" />
              {NHGD_RADIOLOGY_TEST_CODES.trunk.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}

              <EmptyRow />
              <TitleRow title="Add Ons" />
              {NHGD_RADIOLOGY_TEST_CODES.addons.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}

              <Flex
                {...borders}
                {...bodyStyle}
                justifySelf="end"
                alignItems="flex-start"
                flexDir="column"
                height="100%"
              >
                <Text
                  fontWeight="bold"
                  flex={1}
                >{`Radiographer's Initials:`}</Text>
                <Text fontWeight="bold" flex={2}>{`Remarks:`}</Text>
                <Text
                  fontWeight="bold"
                  flex={1}
                >{`Date / Time of Examination:`}</Text>
              </Flex>
            </VStack>
          </Flex>
        </Flex>
      </NhgdLayout>
    </VStack>
  )
}
