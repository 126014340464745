import { Fragment } from 'react'
import { IconType } from 'react-icons'
import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react'

export const SidepanelIconRow = ({
  icon,
  tooltipLabel,
  value,
}: {
  icon: IconType
  tooltipLabel?: string
  value: string
}) => {
  const MaybeTooltip = tooltipLabel ? Tooltip : Fragment
  return (
    <MaybeTooltip {...(tooltipLabel ? { label: tooltipLabel } : {})}>
      <Flex gap={2}>
        <Icon
          as={icon}
          fontSize="1.25rem"
          flexShrink={0}
          fill="base.content.medium"
        />
        <Text textStyle="body-2" color="base.content.default">
          <Text as="span">{value}</Text>
        </Text>
      </Flex>
    </MaybeTooltip>
  )
}
