import { GridItem, SimpleGrid, Text, VStack } from '@chakra-ui/react'

import { CopyableText } from '@/components/CopyableText'
import { Section, Title } from '@/components/SidePanel'
import { ReferralDto } from '~shared/dtos'

export const SummarySection = ({ referral }: { referral: ReferralDto }) => {
  return (
    <Section>
      <Title>Referral Details</Title>
      <SimpleGrid columns={2} spacing={2}>
        <GridItem>
          <Text fontWeight="medium">Patient</Text>
        </GridItem>
        <GridItem alignItems="start" textStyle="body-2">
          <VStack alignItems="start" w="full" gap={0}>
            <CopyableText text={referral.patient.name} fontWeight="medium" />
            <CopyableText
              text={referral.patient.uin}
              color="base.content.medium"
            />
          </VStack>
        </GridItem>
        <GridItem>
          <Text fontWeight="medium">Referring GP</Text>
        </GridItem>
        <GridItem alignItems="start" textStyle="body-2">
          <VStack alignItems="start" gap={0}>
            <CopyableText text={referral.sender.name} fontWeight="medium" />
            <CopyableText
              text={referral.referringDoctor.name}
              color="base.content.medium"
            />
          </VStack>
        </GridItem>
      </SimpleGrid>
    </Section>
  )
}
