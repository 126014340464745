import { Divider, Text, VStack } from '@chakra-ui/react'
import dayjs from 'dayjs'

import { ShowHideSection } from '../ShowHideSection'

import { Note } from './Note'

import { Section, Title } from '@/components/SidePanel'
import { NoteDto } from '~shared/dtos'

export type NotesProps = {
  notes: NoteDto[]
}

export const Notes = ({ notes }: NotesProps) => {
  // Latest note first
  const sortedNotes = notes.sort(
    (a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf(),
  )

  return (
    <Section gap={2}>
      <Title>PAST COMMENTS</Title>
      <ShowHideSection shrinkedHeight={200} maxExpandedHeight={400}>
        <VStack spacing="1px" divider={<Divider />} w="full">
          {sortedNotes.length === 0 && (
            <Text textStyle="body-2" color="base.content.medium" w="full">
              No comments yet
            </Text>
          )}
          {sortedNotes.map((n) => (
            <Note {...n} key={n.id} />
          ))}
        </VStack>
      </ShowHideSection>
    </Section>
  )
}
