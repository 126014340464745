import dayjs from 'dayjs'

import { InstitutionCoreDto } from '../dtos'
export const titleCase = (str: string): string => {
  return str
    .trim()
    .split(/\s+/)
    .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

// distance in metres
export const formatDistance = (distance?: number): string => {
  if (!distance) return '0m'

  const unit = distance >= 1000 ? 'km' : 'm'
  const fixed =
    unit === 'km' ? +(distance / 1000).toFixed(1) : +distance.toFixed(0)
  return Intl.NumberFormat('en-US').format(fixed) + unit
}

export const maskUin = (uin: string) => {
  const ending = uin.replace(/(S|T|F|G|M)\d{4}(\d{3}[a-zA-Z])/gi, '$2')
  return `*****${ending}`
}

export const formatAddress = ({
  postalCode,
  block,
  floorNumber,
  unitNumber,
  streetName,
}: Pick<
  InstitutionCoreDto,
  'block' | 'floorNumber' | 'unitNumber' | 'streetName' | 'postalCode'
>): string => {
  let floorUnit = ''
  if (floorNumber && unitNumber) {
    floorUnit = ` ${floorNumber}-${unitNumber},`
  } else if (floorNumber) {
    floorUnit = ` Floor ${floorNumber},`
  } else if (unitNumber) {
    floorUnit = ` Unit ${unitNumber},`
  }

  return `${block} ${streetName},${floorUnit} S${postalCode}`
}

export const formatTime = (time: Date | string) => {
  const parsed = dayjs(time)

  // Edge-case: weird strings are returned as-is
  if (!parsed.isValid()) return time.toString()

  if (dayjs().isSame(parsed, 'minute')) {
    return `Just now`
  } else {
    return parsed.format('dddd, D MMM YYYY HH:mm A')
  }
}
