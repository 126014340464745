import { PropsWithChildren } from 'react'
import { Flex, FlexProps } from '@chakra-ui/react'

export const Section = ({
  children,
  ...rest
}: PropsWithChildren<FlexProps>) => {
  return (
    <Flex
      gap={3}
      borderRadius="lg"
      alignItems="flex-start"
      padding={3}
      background="base.canvas.alt"
      flexDir="column"
      w="full"
      {...rest}
    >
      {children}
    </Flex>
  )
}
