import { BiFile } from 'react-icons/bi'
import { HStack, Text, VStack } from '@chakra-ui/react'

import { ShowHideSection } from '../ShowHideSection'

import { Section, Title } from '@/components/SidePanel'
import { AttachmentDto } from '~shared/dtos/attachment.dto'

export type AttachmentProps = {
  attachments?: AttachmentDto[]
}

export const AttachmentSection = ({ attachments }: AttachmentProps) => {
  return (
    <Section gap={2}>
      <Title>ATTACHMENTS ({attachments?.length ?? 0})</Title>
      <ShowHideSection shrinkedHeight={200} maxExpandedHeight={400}>
        <VStack spacing="1px" w="full">
          {attachments?.length === 0 && (
            <Text textStyle="body-2" color="base.content.medium" w="full">
              No attachments provided by GP
            </Text>
          )}
          {attachments?.map((attachment, index) => (
            <HStack
              key={`${attachment.name}-${index}`}
              w="full"
              borderWidth="1px"
              borderRadius="md"
              borderColor="base.divider.strong"
              backgroundColor="base.canvas.backdrop"
              m={1}
              fontWeight="medium"
              p={2}
              display="flex"
              alignItems="flex-start"
            >
              <BiFile style={{ marginTop: '4px' }} />
              <Text
                as="a"
                href={attachment.url}
                color="base.content.strong"
                target="_blank"
                noOfLines={2}
                flex={1}
                overflow="hidden"
                textOverflow="ellipsis"
                wordBreak="break-word"
              >
                {attachment.name}
              </Text>
            </HStack>
          ))}
        </VStack>
      </ShowHideSection>
    </Section>
  )
}
