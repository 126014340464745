import { Controller, useFormContext } from 'react-hook-form'
import {
  FormControl,
  RadioGroup,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { FormErrorMessage, Radio } from '@opengovsg/design-system-react'

import {
  EaDecisionEnum,
  EaFormFields,
  requiredRule,
} from '../../email-ack.types'

export const DecisionField = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<EaFormFields>()

  return (
    <FormControl isInvalid={!!errors.decision}>
      <VStack align="stretch" spacing="1rem">
        <Controller
          name="decision"
          rules={requiredRule}
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup value={value} onChange={onChange}>
              <Wrap w="full">
                <WrapItem flexGrow={1} minWidth="0" bgColor="white">
                  <Radio variant="radioCard" value={EaDecisionEnum.ACCEPT}>
                    Schedule appointment
                  </Radio>
                </WrapItem>
                <WrapItem flexGrow={1} minWidth="0" bgColor="white">
                  <Radio variant="radioCard" value={EaDecisionEnum.COMMENT}>
                    Request for more details
                  </Radio>
                </WrapItem>
                <WrapItem flexGrow={1} minWidth="0" bgColor="white">
                  <Radio variant="radioCard" value={EaDecisionEnum.REJECT}>
                    Reject referral
                  </Radio>
                </WrapItem>
              </Wrap>
            </RadioGroup>
          )}
        />
        <FormErrorMessage>{errors.decision?.message}</FormErrorMessage>
      </VStack>
    </FormControl>
  )
}
