import { Controller, useFormContext } from 'react-hook-form'
import { FormControl, Text } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  Infobox,
  Textarea,
} from '@opengovsg/design-system-react'

import { EaFormFields, requiredRule } from '../../email-ack.types'

export const RejectField = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<EaFormFields>()

  return (
    <>
      <Infobox variant="warning">
        <Text textStyle="body-2" color="base.content.default">
          Before rejecting, ensure:
          <ul style={{ paddingLeft: '1rem' }}>
            <li>You have informed the patient about the cancellation, or</li>
            <li>Patient has an existing appointment, or</li>
            <li>Referral is inappropriate for this clinic</li>
          </ul>
          <br />
          Need clarification from the GP? Use &apos;Request for more
          details&apos; option instead.
        </Text>
      </Infobox>
      <FormControl isInvalid={!!errors.noteContent}>
        <FormLabel
          isRequired
          description="We will send this to the GP so they know how to follow up with the patient."
        >
          Reason for rejection
        </FormLabel>
        <Controller
          name="noteContent"
          rules={requiredRule}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Textarea
              value={value}
              onChange={onChange}
              placeholder="Explain why this referral needs to be resubmitted."
            />
          )}
        />
        <FormErrorMessage>{errors.noteContent?.message}</FormErrorMessage>
      </FormControl>
    </>
  )
}
