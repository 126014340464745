import { BiAbacus, BiCalendar, BiCheck, BiPencil, BiX } from 'react-icons/bi'
import { Flex, IconButton, Text, VStack } from '@chakra-ui/react'

import { Section, SidepanelIconRow, Title } from '@/components/SidePanel'
import { ReferralDto } from '~shared/dtos'
import { ReferralStatus } from '~shared/types'
import { formatTime } from '~shared/utils/human-readable'

const ACKABLE_STATUSES: ReferralStatus[] = [
  'pending-email-ack',
  'rejected',
  'confirmed',
]

export const CurrentStatusSection = ({
  referral,
  onEdit,
}: {
  referral: ReferralDto
  onEdit: () => void
}) => {
  return (
    <Section>
      <Flex justifyContent="space-between" w="full" alignItems="center">
        <Title>Current Status</Title>
        {ACKABLE_STATUSES.includes(referral.status) && (
          <IconButton
            onClick={onEdit}
            icon={<BiPencil fontSize="1.25rem" />}
            variant="clear"
            aria-label="edit"
            size="xs"
            colorScheme="gray"
          />
        )}
      </Flex>
      <InnerStatusFactory referral={referral} />
    </Section>
  )
}

const InnerStatusFactory = ({ referral }: { referral: ReferralDto }) => {
  switch (referral.status) {
    case 'confirmed':
      return <ConfirmedStatus referral={referral} />
    case 'rejected':
      return <RejectedStatus />
    case 'draft':
    case 'cancelled':
    case 'pending-cancelled':
      return <WithdrawnStatus />
    default: // Just in case we have back-compat issues
      return <DefaultStatus referral={referral} />
  }
}

const ConfirmedStatus = ({ referral }: { referral: ReferralDto }) => {
  if (!referral.timeslotStartAt) return null

  return (
    <>
      <Flex color="utility.feedback.success" gap={2}>
        <BiCheck fontSize="1.25rem" />
        <VStack alignItems="start" gap={0}>
          <Text textStyle="subhead-1">Referral confirmed!</Text>
          <Text textStyle="body-2">The patient and GP will be notified.</Text>
        </VStack>
      </Flex>
      <SidepanelIconRow
        icon={BiCalendar}
        value={formatTime(referral.timeslotStartAt)}
      />
    </>
  )
}

const RejectedStatus = () => {
  return (
    <>
      <Flex color="utility.feedback.critical" gap={2}>
        <BiX fontSize="1.25rem" />
        <VStack alignItems="start" gap={0}>
          <Text textStyle="subhead-1">Referral rejected</Text>
          <Text textStyle="body-2">The patient and GP will be notified.</Text>
        </VStack>
      </Flex>
    </>
  )
}

const WithdrawnStatus = () => {
  return (
    <>
      <Flex color="base.content.medium" gap={2}>
        <BiX fontSize="1.25rem" />
        <VStack alignItems="start" gap={0}>
          <Text textStyle="subhead-1">
            Withdrawn by the referring GP doctor
          </Text>
          <Text textStyle="body-2">
            No further action is required from you.
          </Text>
        </VStack>
      </Flex>
    </>
  )
}

const DefaultStatus = ({ referral }: { referral: ReferralDto }) => {
  return (
    <>
      <Flex color="utility.feedback.info" gap={2}>
        <BiAbacus fontSize="1.25rem" />
        <VStack alignItems="start" gap={0}>
          <Text textStyle="subhead-1">
            {`Referral status: ${referral.status}`}
          </Text>
        </VStack>
      </Flex>
    </>
  )
}
