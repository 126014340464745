import { ChasReferralFormView } from './chas/ChasReferralFormView'
import { DefaultReferralFormView } from './default'
import { NhgdLabFormView, NhgdRadiologyFormView } from './nhg'

import { PublicReferralDto } from '~shared/dtos'

export const PublicReferralViewFactory = ({
  referral,
}: {
  referral: PublicReferralDto
}) => {
  switch (referral.formId) {
    case 'nhgd_laboratory_test':
    case 'nhgd_spirometry':
      return <NhgdLabFormView referral={referral} />

    case 'nhgd_xray':
    case 'nhgd_mammogram':
    case 'nhgd_ultrasound':
    case 'nhgd_bmd':
      return <NhgdRadiologyFormView referral={referral} />

    case 'chas':
      return <ChasReferralFormView referral={referral} />

    case null: // back-compat
    case 'default':
    default:
      return <DefaultReferralFormView referral={referral} />
  }
}
