import { Box, Flex, HStack, Text } from '@chakra-ui/react'

import { bodyStyle, borders, headerStyle } from '../chas-layer-styles'
import { ChasFormClinicDetails } from '../extract-chas-form-fields'

import { ClinicType } from '~shared/form-fields/chas/chas-form-fields.dto'

export const ChasFormClinicInfo = (info: ChasFormClinicDetails) => {
  return (
    <Flex flexDir="column">
      <Box {...borders} {...headerStyle}>
        Part III: Clinic Information
      </Box>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Clinic Name`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.referringClinic.name}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Clinic HCI Code`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.referringClinic.hci}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Clinic Service License Number`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.referringClinic.hci}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Doctor's Name`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.doctor.name}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`MCR No.`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.doctor.mcr}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Clinic Address`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.referringClinic.address}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Clinic Contact No.`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.referringClinic.contactNo}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Clinic Email`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.referringClinic.email}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Clinic Type`}</b>
        </Box>
        <HStack {...borders} px={1} py={0} flex={1} flexWrap="wrap" rowGap={0}>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.referringClinic.clinicType === ClinicType.ChasOnly}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>CHAS-only</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={
                info.referringClinic.clinicType === ClinicType.ChasAndHsg
              }
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>CHAS and HSG</Text>
          </HStack>
        </HStack>
      </Flex>
    </Flex>
  )
}
