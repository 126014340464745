import React, { useEffect, useRef, useState } from 'react'
import { Box, Link, VStack } from '@chakra-ui/react'

export const ShowHideSection = ({
  children,
  shrinkedHeight = 200,
  maxExpandedHeight,
}: {
  children: JSX.Element
  shrinkedHeight?: number
  maxExpandedHeight?: number
}) => {
  const [showAllContent, setShowAllContent] = useState(false)
  const [containerHeight, setContainerHeight] = useState(0)
  const containerRef = useRef(null)

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setContainerHeight(containerRef.current['offsetHeight'])
    }
  }, [children])

  const showSeeMoreButton = !showAllContent && containerHeight >= shrinkedHeight

  return (
    <VStack alignItems="left" w="full" ref={containerRef}>
      <VStack position="relative">
        {React.cloneElement(children, {
          height: showAllContent ? '100%' : 'fit-content',
          maxHeight: showAllContent
            ? maxExpandedHeight ?? '100%'
            : `${shrinkedHeight}px`,
          overflow: showAllContent && maxExpandedHeight ? 'auto' : 'hidden',
        })}
        {showSeeMoreButton && (
          <Box
            bg="linear-gradient(rgba(255,255,255,0), rgba(255,255,255,.8))"
            h="50px"
            w="full"
            position="absolute"
            bottom={0}
          />
        )}
      </VStack>

      {showSeeMoreButton && (
        <Link
          textStyle="body-1"
          fontWeight={500}
          color="interaction.main.default"
          onClick={() => setShowAllContent(true)}
        >
          See more
        </Link>
      )}
    </VStack>
  )
}
