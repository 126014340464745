import { Box, Flex, Text, VStack } from '@chakra-ui/react'

import { PublicReferralDto } from '~shared/dtos'

export const ReferralRequest = ({
  referral,
}: {
  referral: PublicReferralDto
}) => {
  return (
    <Flex alignItems="flex-start" gap={4} flexDir="column">
      <Text
        textStyle="body-2"
        fontWeight="600"
        letterSpacing="1px"
        color="base.content.default"
      >
        REFERRAL FORM
      </Text>
      {referral.formResponses.map(({ question, answer, id }) => (
        <Box position="relative" w="full" key={id}>
          <VStack w="full" spacing={0} align="left">
            <Text
              w="full"
              textColor="interaction.support.placeholder"
              textStyle="legal"
              fontSize="0.625rem"
              fontWeight="semibold"
              isTruncated={true}
              mr="8px"
            >
              {question}
            </Text>
            {typeof answer === 'string' || typeof answer === 'number' ? (
              <Text textStyle="body-2" whiteSpace="break-spaces">
                {answer}
              </Text>
            ) : (
              answer.map((row) => (
                <Text key={row} textStyle="body-2" whiteSpace="break-spaces">
                  {row}
                </Text>
              ))
            )}
          </VStack>
        </Box>
      ))}
    </Flex>
  )
}
