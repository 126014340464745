export const REFERRAL_STATUS_MAP = {
  draft: ['draft'],
  pending: [
    'created',
    // In our job queue
    'processing-has',
    'processing-pb',
    'processing-ea',
    // e.g. waiting on patient to fill up FormSG
    'pending-singhealth-formsg',
    // Referral sent to PB, waiting on PB call centre to confirm the appt
    'pending-singhealth-call-centre',
    'referral-confirmed-pending-appt',
    'pending-email-ack',
    // Timeslot chosen is not available - let user reselect
    'pending-amend-timeslot',
  ],
  confirmed: [
    // Appointment confirmed
    'confirmed',
  ],
  rejected: [
    // Rejected by our validation logic or PB
    'rejected',
    // RefX could not complete processing, and has given up.
    'failed',
  ],
  cancelled: ['pending-cancelled', 'cancelled'],
} as const
type ReferralStatusMap = typeof REFERRAL_STATUS_MAP

export const REFERRAL_STATUS_FILTERS = Object.keys(
  REFERRAL_STATUS_MAP,
) as ReferralStatusFilter[]
// TODO - revisit this with the state-machine conversation
/**
 * HAS needs to query RefX for referrals, filtered by status.
 * To prevent coupling of internal enum and the API signature,
 * this is a set of stable queryable statuses.
 * ReferralStatusInternal refines this partition.
 *  */
export type ReferralStatusFilter = keyof ReferralStatusMap

export type ReferralStatus = ReferralStatusMap[ReferralStatusFilter][number]

export const REFERRAL_STATUS_REVERSE_MAP: Readonly<
  Record<ReferralStatus, ReferralStatusFilter>
> = Object.keys(REFERRAL_STATUS_MAP).reduce((acc, key) => {
  const statusKey = key as ReferralStatusFilter
  REFERRAL_STATUS_MAP[statusKey].forEach((status: ReferralStatus) => {
    acc[status] = statusKey
  })
  return acc
}, {} as { [key in ReferralStatus]: ReferralStatusFilter })
export const REFERRAL_STATUSES = Object.values(REFERRAL_STATUS_MAP).flat()

export enum ViewTemplate {
  NhgdLab = 'nhgd-lab',
  NhgdRadiology = 'nhgd-radiology',
  Chas = 'chas',
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export const REFERRAL_ROLES = ['sender', 'receiver'] as const
export type ReferralRole = (typeof REFERRAL_ROLES)[number]
