import { useParams } from 'react-router-dom'

import { PublicReferralViewFactory } from './PublicReferralViewFactory'

import { LoadingScreen } from '@/components/LoadingScreen'
import { useNamedDocumentTitle } from '@/hooks/useNamedDocumentTitle'
import { usePublicReferral } from '@/hooks/usePublicReferral'

export const PublicReferralPage = () => {
  const { referralId } = useParams()
  const { data, isPending, isError } = usePublicReferral(referralId ?? '')
  useNamedDocumentTitle(data)

  // TODO - better states
  if (isError) return <LoadingScreen />
  if (isPending) return <LoadingScreen />

  return <PublicReferralViewFactory referral={data} />
}
