import { VStack } from '@chakra-ui/react'

import { CommentField } from './CommentField'
import { DateTimeField } from './DateTimeField'

export const AcceptField = () => {
  return (
    <VStack gap={5} alignItems="stretch">
      <DateTimeField />
      <CommentField />
    </VStack>
  )
}
