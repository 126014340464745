import { useMutation } from '@tanstack/react-query'

import { queryKeys } from '@/constants/query-keys'
import { ApiService } from '@/services'
import { queryClient } from '@/utils/query'
import { CreateNoteReq, NoteDto } from '~shared/dtos'

export const useCreateNote = () => {
  return useMutation({
    mutationFn: async ({
      referralId,
      token,
      ...body
    }: {
      referralId?: string
      token: string | null
    } & CreateNoteReq) => {
      const data = await ApiService.extend({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .post(`v1/referrals/${referralId}/notes`, {
          json: body,
        })
        .json<NoteDto>()
      return data
    },
    onSuccess: (_, { referralId }) => {
      queryClient
        .invalidateQueries({
          queryKey: queryKeys.referral(referralId ?? ''),
        })
        // eslint-disable-next-line no-console
        .catch(console.error)
    },
  })
}
