import { Text, VStack, Wrap } from '@chakra-ui/react'
import dayjs from 'dayjs'

import { formatDate } from '@/utils/human-readable'
import { NoteDto } from '~shared/dtos'

export const Note = ({ content, authorName, updatedAt }: NoteDto) => {
  return (
    <VStack
      flex={1}
      alignItems="start"
      w="full"
      px={1}
      py={3}
      position="relative"
      borderRadius="sm"
    >
      <Wrap align="center" spacingX={2} spacingY={0}>
        <Text
          textStyle="body-2"
          color="base.content.default"
          fontWeight="semibold"
        >
          {authorName}
        </Text>
        <Text textStyle="caption-2" color="base.content.medium">
          {formatDate({ date: dayjs(updatedAt).format() })}
        </Text>
      </Wrap>
      <Text
        textStyle="body-2"
        color="base.content.strong"
        whiteSpace="pre-line"
        wordBreak="break-word"
      >
        {content}
      </Text>
    </VStack>
  )
}
