import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { useToast } from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import { EaDecisionEnum, EaFormFields } from '../../email-ack.types'

import {
  useAcknowledgeReferralWithAcceptance,
  useAcknowledgeReferralWithRejection,
} from '@/hooks/useAcknowledgeReferral'
import { useCreateNote } from '@/hooks/useCreateNote'
import { ReferralDto } from '~shared/dtos'

export const SubmitAcknowledgementButton = ({
  referral,
}: {
  referral: ReferralDto
}) => {
  const { handleSubmit, watch, setValue } = useFormContext<EaFormFields>()
  const { referralId } = useParams()
  const toast = useToast()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('auth')

  const currentDecision = watch('decision')
  const acceptMutation = useAcknowledgeReferralWithAcceptance()
  const rejectMutation = useAcknowledgeReferralWithRejection()
  const createNoteMutation = useCreateNote()

  const onSubmit = useCallback(
    async (fields: EaFormFields) => {
      const toastCallback = {
        onSuccess: () => {
          toast({
            status: 'success',
            description: 'Response saved.',
          })
          setValue('noteContent', '')
        },
        onError: (error: Error) => {
          toast({
            status: 'error',
            description: error.message ?? 'Something went wrong.',
          })
        },
      }

      if (fields.decision === EaDecisionEnum.COMMENT && fields.noteContent) {
        await createNoteMutation.mutateAsync({
          referralId,
          token,
          content: fields.noteContent,
          // TODO - should be signed in token next time
          authorHciCode: referral.offering.institutionHciCode,
        })
        toastCallback.onSuccess()
        return
      }

      if (fields.decision === EaDecisionEnum.ACCEPT) {
        const date = dayjs(fields.date).format('YYYY-MM-DD')
        const appointmentTime = dayjs(`${date} ${fields.time}`).valueOf()

        acceptMutation.mutate(
          {
            referralId,
            token,
            appointmentTime,
            noteContent: fields.noteContent,
          },
          toastCallback,
        )
      } else {
        rejectMutation.mutate(
          {
            referralId,
            token,
            rejectionMessage: fields.noteContent ?? '',
          },
          toastCallback,
        )
      }
    },
    [
      toast,
      setValue,
      createNoteMutation,
      referralId,
      token,
      referral.offering.institutionHciCode,
      acceptMutation,
      rejectMutation,
    ],
  )

  const buttonText = useMemo(() => {
    if (referral.status === 'pending-email-ack') return 'Submit'
    if (currentDecision === EaDecisionEnum.ACCEPT) return 'Reschedule'
    return 'Submit'
  }, [currentDecision, referral.status])

  return (
    <Button
      onClick={handleSubmit(onSubmit)}
      isLoading={
        acceptMutation.isPending ||
        rejectMutation.isPending ||
        createNoteMutation.isPending
      }
    >
      {buttonText}
    </Button>
  )
}
