import { useParams, useSearchParams } from 'react-router-dom'

import { Acknowledge } from './components/Acknowledge/Acknowledge'
import { ErrorInAcknowledgement } from './components/ErrorInAcknowledgement'

import { LoadingScreen } from '@/components/LoadingScreen'
import { useGetReferral } from '@/hooks/useGetReferral'
import { useNamedDocumentTitle } from '@/hooks/useNamedDocumentTitle'

export const EmailAckPage = () => {
  const { referralId } = useParams()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('auth') ?? ''

  const {
    data: referral,
    isPending,
    isError,
  } = useGetReferral({ referralId: referralId ?? '', token })

  useNamedDocumentTitle(referral)

  if (isPending) return <LoadingScreen />
  if (isError) return <ErrorInAcknowledgement />

  return <Acknowledge referral={referral} />
}
