import { IsGovSgEmail } from '../decorators/is-gov-sg-email'

export class GenerateOtpDto {
  @IsGovSgEmail({
    message: 'This does not appear to be a gov.sg email address',
  })
  email: string
}

export interface VerifyOtpDto {
  token: string
  email: string
}

export type SendLoginOtpRequestDto = GenerateOtpDto

export interface SendLoginOtpResponseDto {
  message: string
}

export type VerifyOtpRequestDto = VerifyOtpDto

export interface VerifyOtpResponseDto {
  message: string
}

export interface WhoAmIResponseDto {
  id: number
  email: string
}

import {
  IsEnum,
  IsIn,
  IsOptional,
  IsUUID,
  ValidateNested,
} from 'class-validator'

import { System, SYSTEMS } from './system.types'

export class AuthToken {
  @IsOptional()
  @IsUUID()
  referralId?: string

  @IsIn(SYSTEMS)
  system: System

  @ValidateNested({ each: true })
  permissions: Permission[]
}

// TODO - maybe, rather than by action, should be in role categories?
// e.g. author -> create, cancel, delete draft, add note
//      recipient -> acknowledge, add note
//      others -> read
export enum Scope {
  ALL_PERMISSIONS = 'all_permissions',
  CREATE_REFERRALS = 'create_referrals',
  CANCEL_REFERRALS = 'cancel_referrals',
  ACKNOWLEDGE_REFERRALS = 'acknowledge_referrals',
  DELETE_DRAFTS = 'delete_drafts',
  READ_PERMISSION = 'read_permission',
}

// Currently not used. Use if we need more fine-grained information per scope
export class ApiKeyPermissionMetadata {}

export class Permission {
  @IsEnum(Scope)
  scope: Scope

  @IsOptional()
  @ValidateNested()
  metadata?: ApiKeyPermissionMetadata
}
