import { Controller, useFormContext } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import {
  FormErrorMessage,
  FormLabel,
  Textarea,
} from '@opengovsg/design-system-react'

import { EaFormFields, requiredRule } from '../../email-ack.types'

export const CommentField = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<EaFormFields>()

  return (
    <FormControl isInvalid={!!errors.noteContent}>
      <FormLabel isRequired description="We will send this to the GP.">
        Notes for GP doctor
      </FormLabel>
      <Controller
        name="noteContent"
        rules={requiredRule}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Textarea value={value} onChange={onChange} />
        )}
      />
      <FormErrorMessage>{errors.noteContent?.message}</FormErrorMessage>
    </FormControl>
  )
}
