import { Box, Divider, Flex, HStack, Text, VStack } from '@chakra-ui/react'

import { PdfDocument } from '../PdfDocument'

import { NhgdLayout } from './components/NhgdLayout'
import { NhgdSentBy } from './components/NhgdSentBy'
import { EmptyRow, Row, TitleRow } from './components/TableRow'
import { extractNhgdLabFields } from './extract-nhgd-lab-form-fields'
import { bodyStyle, borders, headerStyle } from './nhg-layer-styles'

import NhgdLabGlossaryPdf from '@/assets/nhgd-lab-glossary.pdf'
import { DATE_DISPLAY_FORMATS, formatDate } from '@/utils/human-readable'
import { PublicReferralDto } from '~shared/dtos'
import {
  NHGD_LAB_TEST_CODES,
  NhgdClinicalLabFasting,
  NhgdClinicalLabPurpose,
  NhgdClinicalLabTestType,
  NhgdCollectionMode,
  NhgdPaymentMode,
  NhgdPriority,
} from '~shared/form-fields'

/** Priorities for this component is to be print-friendly */
export const NhgdLabFormView = ({
  referral,
}: {
  referral: PublicReferralDto
}) => {
  const data = extractNhgdLabFields(referral)

  return (
    <VStack>
      {/* Page 1 */}
      <NhgdLayout title="Laboratory Investigation Form" referralId={data.id}>
        <Flex flexDir="column" alignItems="stretch" border="1px">
          <Flex {...headerStyle}>
            <Box width="66.7%" {...borders}>
              {`Patient's Information`}
            </Box>
            <Box width="33.3%" {...borders}>{`Referral Information`}</Box>
          </Flex>

          <Flex>
            <Flex flexDir="column" width="66.7%" {...borders} {...bodyStyle}>
              <Text>
                <b>{`Name: `}</b>
                {data.patient.name}
              </Text>
              <Flex>
                <Text flex={1}>
                  <b>{`NRIC / FIN: `}</b>
                  {data.patient.uin}
                </Text>
                <Text flex={1}>
                  <b>{`Date of Birth: `}</b>
                  {formatDate({
                    date: data.patient.dob,
                    format: DATE_DISPLAY_FORMATS.DATE_ONLY,
                  })}
                </Text>
              </Flex>
              <Flex>
                <Text flex={1}>
                  <b>{`Sex: `}</b>
                  {data.patient.gender}
                </Text>
                <Text flex={1}>
                  <b>{`Contact Number: `}</b>
                  {`${data.patient.phoneNumber} (HP)`}
                </Text>
              </Flex>
            </Flex>
            <NhgdSentBy data={data} width="33.3%" />
          </Flex>

          <Flex {...headerStyle}>
            <Box width="66.7%" {...borders}>
              {`Patient's History`}
            </Box>
            <Box width="33.3%" {...borders}>
              {`Please specify:`}
            </Box>
          </Flex>
          <Flex>
            <Flex flexDir="column" width="66.7%" {...borders} {...bodyStyle}>
              <Text>
                <b>{`Relevant History / Findings`}</b>
              </Text>
              <Text>{data.note}</Text>
            </Flex>

            <Flex
              width="33.3%"
              alignItems="flex-start"
              {...borders}
              {...bodyStyle}
              px={0}
              flexDir="column"
              h="100%"
              gap={1}
            >
              <HStack alignItems="center" flex={1} px={2}>
                <b>OR</b>
                <Flex flexDir="column">
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={data.priority === NhgdPriority.Stat}
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>STAT</Text>
                  </HStack>
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={data.priority === NhgdPriority.Routine}
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Routine</Text>
                  </HStack>
                </Flex>
              </HStack>
              <Divider borderColor="black" />

              <HStack alignItems="center" flex={1} px={2}>
                <b>OR</b>
                <Flex flexDir="column">
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={
                        data.purpose === NhgdClinicalLabPurpose.Screening
                      }
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Screening</Text>
                  </HStack>
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={
                        data.purpose === NhgdClinicalLabPurpose.Diagnosis
                      }
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Diagnosis</Text>
                  </HStack>
                </Flex>
              </HStack>
              <Divider borderColor="black" />

              <HStack alignItems="center" flex={1} px={2}>
                <b>OR</b>
                <Flex flexDir="column">
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={data.paymentMode === NhgdPaymentMode.Patient}
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Patient pay cash</Text>
                  </HStack>
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={data.paymentMode === NhgdPaymentMode.Clinic}
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Bill Clinic</Text>
                  </HStack>
                </Flex>
              </HStack>
              <Divider borderColor="black" />

              <HStack alignItems="center" flex={1} px={2}>
                <b>OR</b>
                <Flex flexDir="column">
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={
                        data.collectionMode === NhgdCollectionMode.Dispatch
                      }
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Despatch to clinic</Text>
                  </HStack>
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={
                        data.collectionMode === NhgdCollectionMode.Patient
                      }
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Patient to collect</Text>
                  </HStack>
                </Flex>
              </HStack>
              <Divider borderColor="black" />
              <HStack alignItems="center" flex={1} px={2}>
                <b>OR</b>
                <Flex flexDir="column">
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={data.fasting === NhgdClinicalLabFasting.Yes}
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Fasting</Text>
                  </HStack>
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={data.fasting === NhgdClinicalLabFasting.No}
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>Non-fasting</Text>
                  </HStack>
                </Flex>
              </HStack>
              <Divider borderColor="black" />

              <Flex flexDir="column" px={2}>
                <Text fontWeight="bold">Test Type(s)</Text>
                <Flex gap={2}>
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={
                        data.testTypes.filter(
                          (testType) =>
                            testType === NhgdClinicalLabTestType.Blood,
                        ).length > 0
                      }
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>{NhgdClinicalLabTestType.Blood}</Text>
                  </HStack>
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={
                        data.testTypes.filter(
                          (testType) =>
                            testType === NhgdClinicalLabTestType.Urine,
                        ).length > 0
                      }
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>{NhgdClinicalLabTestType.Urine}</Text>
                  </HStack>
                  <HStack>
                    <input
                      type="checkbox"
                      readOnly
                      checked={
                        data.testTypes.filter(
                          (testType) =>
                            testType === NhgdClinicalLabTestType.Others,
                        ).length > 0
                      }
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <Text>{NhgdClinicalLabTestType.Others}</Text>
                  </HStack>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </NhgdLayout>

      {/* Page 2 */}
      <NhgdLayout
        title="Laboratory Investigation Form"
        referralId={data.id}
        showHeaderOnlyOnPrint
      >
        <Flex flexDir="column" alignItems="stretch" border="1px">
          <Flex
            textAlign="center"
            justifyContent="center"
            {...borders}
            {...headerStyle}
          >
            {`Type of Laboratory Test`}
          </Flex>
          <Flex justifyContent="stretch">
            <VStack spacing={0} width="33.4%" alignItems="stretch">
              <TitleRow title="Diabetes / Hypertension" />
              {NHGD_LAB_TEST_CODES.diabetes.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}
              <EmptyRow />
              <TitleRow title="Stool / Others" />
              {NHGD_LAB_TEST_CODES.stool.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}
              <TitleRow title="ECG" />
              {NHGD_LAB_TEST_CODES.ecg.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}
              <EmptyRow />
              <TitleRow title="Spirometry" />
              {NHGD_LAB_TEST_CODES.spirometry.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}
            </VStack>
            <VStack spacing={0} width="33.3%" alignItems="stretch">
              <TitleRow title="Haematology" />
              {NHGD_LAB_TEST_CODES.haematology.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}

              <TitleRow title="Urine" />
              {NHGD_LAB_TEST_CODES.urine.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}
              <EmptyRow />
              <EmptyRow />
              <EmptyRow />
            </VStack>
            <VStack spacing={0} width="33.3%" alignItems="stretch">
              <TitleRow title="Kidney Renal" />
              {NHGD_LAB_TEST_CODES.kidney.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}

              <TitleRow title="Infectious Disease" />
              {NHGD_LAB_TEST_CODES.infectious.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}
            </VStack>
          </Flex>
        </Flex>
      </NhgdLayout>

      {/* Page 3 */}
      <NhgdLayout
        title="Laboratory Investigation Form"
        referralId={data.id}
        showHeaderOnlyOnPrint
      >
        <Flex flexDir="column" alignItems="stretch" border="1px">
          <Flex justifyContent="stretch">
            <VStack spacing={0} width="33.4%" alignItems="stretch">
              <TitleRow title="Single Test" />
              {NHGD_LAB_TEST_CODES.singleTest.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}
              <EmptyRow />
              <EmptyRow />
            </VStack>
            <VStack spacing={0} width="33.3%" alignItems="stretch">
              <TitleRow title="Cancer Marker" />
              {NHGD_LAB_TEST_CODES.cancer.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}
              <EmptyRow />
              <EmptyRow />
              <EmptyRow />
              <TitleRow title="Drugs" />
              {NHGD_LAB_TEST_CODES.drugs.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}
            </VStack>
            <VStack spacing={0} width="33.3%" alignItems="stretch">
              <TitleRow title="Liver Function" />
              {NHGD_LAB_TEST_CODES.liver.map((row) => (
                <Row
                  key={row.code}
                  {...row}
                  isChecked={data.testCodes.includes(row.code)}
                />
              ))}
              <Flex
                {...bodyStyle}
                lineHeight="1rem"
                fontSize="10px"
                letterSpacing="-0.026em"
                justifySelf="end"
                alignItems="stretch"
                flexDir="column"
                height="100%"
              >
                <VStack
                  spacing={0}
                  flex={1}
                  w="full"
                  alignItems="start"
                  {...borders}
                >
                  <Text fontWeight="bold">{`Phlebotomist:`}</Text>
                  <Text>{`(Initials / Date)`}</Text>
                  <Text pt={8}>{`Collection Time:`}</Text>
                </VStack>

                <Text
                  fontWeight="bold"
                  flex={1}
                  {...borders}
                >{`Results:`}</Text>
                <VStack
                  spacing={0}
                  flex={1}
                  w="full"
                  alignItems="start"
                  {...borders}
                >
                  <Text fontWeight="bold">{`Results:`}</Text>

                  <Text pt={8} fontWeight="bold">{`Med Tech:`}</Text>
                  <Text>{`(Initials / Date)`}</Text>
                </VStack>
              </Flex>
            </VStack>
          </Flex>
        </Flex>
      </NhgdLayout>
      <Flex justifyContent="center">
        <PdfDocument file={NhgdLabGlossaryPdf} />
      </Flex>
    </VStack>
  )
}
