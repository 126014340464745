import { useEffect, useState } from 'react'
import { StackProps, Text, VStack } from '@chakra-ui/react'

import { CurrentStatusSection } from './CurrentStatusSection'
import { ReviewSection } from './ReviewSection'
import { SummarySection } from './SummarySection'

import { AttachmentSection, Notes } from '@/components/SidePanel'
import { ReferralDto } from '~shared/dtos'

export const AcknowledgeForm = ({
  referral,
  ...props
}: { referral: ReferralDto } & StackProps) => {
  useEffect(() => {
    setIsEditing(referral.status === 'pending-email-ack')
  }, [referral.status])

  const [isEditing, setIsEditing] = useState(
    referral.status === 'pending-email-ack',
  )

  return (
    <VStack h="fit-content" spacing={3} {...props}>
      <Text textStyle="subhead-1" color="base.content.strong" w="full">
        Referral to {referral.offering.name}
      </Text>
      <SummarySection referral={referral} />
      <AttachmentSection attachments={referral.attachments} />
      <Notes notes={referral.notes} />

      {isEditing ? (
        <ReviewSection
          referral={referral}
          onCancelEdit={() => setIsEditing(false)}
        />
      ) : (
        <CurrentStatusSection
          referral={referral}
          onEdit={() => setIsEditing(true)}
        />
      )}
    </VStack>
  )
}
