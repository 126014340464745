import { FormProvider, useForm } from 'react-hook-form'
import { Button, ButtonGroup } from '@chakra-ui/react'

import { EaDecisionEnum, EaFormFields } from '../../email-ack.types'

import { AcceptField } from './AcceptField'
import { CommentField } from './CommentField'
import { DecisionField } from './DecisionField'
import { RejectField } from './RejectField'
import { SubmitAcknowledgementButton } from './SubmitAcknowledgementButton'

import { Section, Title } from '@/components/SidePanel'
import { ReferralDto } from '~shared/dtos'

export const ReviewSection = ({
  referral,
  onCancelEdit,
}: {
  referral: ReferralDto
  onCancelEdit: () => void
}) => {
  const formMethods = useForm<EaFormFields>({
    defaultValues: { decision: EaDecisionEnum.ACCEPT, time: '' },
    mode: 'onSubmit',
  })
  const decision = formMethods.watch('decision')

  return (
    <FormProvider {...formMethods}>
      <Section>
        <Title>Review</Title>
        <DecisionField />
        {decision === EaDecisionEnum.REJECT && <RejectField />}
        {decision === EaDecisionEnum.COMMENT && <CommentField />}
        {decision === EaDecisionEnum.ACCEPT && <AcceptField />}
        <ButtonGroup justifyContent="end" w="full">
          {referral.status !== 'pending-email-ack' && (
            <Button onClick={onCancelEdit} variant="outline">
              Cancel
            </Button>
          )}
          <SubmitAcknowledgementButton referral={referral} />
        </ButtonGroup>
      </Section>
    </FormProvider>
  )
}
