import { BiAlarm, BiHomeCircle, BiMoney } from 'react-icons/bi'
import { HStack, Text } from '@chakra-ui/react'

import { titleCase } from 'utils/human-readable'

import { PublicReferralDto } from '~shared/dtos'

export const ReferralAppointmentDetails = ({
  referral,
}: {
  referral: PublicReferralDto
}) => {
  return (
    <>
      <HStack w="full" color="base.divider.strong">
        <BiHomeCircle fontSize="1.25rem" />
        <Text textStyle="body-2" color="base.content.default">
          {`${referral.offering.institution.name} - ${titleCase(
            referral.offering.specialty,
          )}`}
        </Text>
      </HStack>
      <HStack w="full" color="base.divider.strong">
        <BiMoney fontSize="1.25rem" />
        <Text textStyle="body-2" color="base.content.default">
          {referral.isSubsidised ? 'Subsidised' : 'Not subsidised'}
        </Text>
      </HStack>
      {referral.isUrgent && (
        <HStack w="full" color="base.divider.strong">
          <BiAlarm fontSize="1.25rem" />
          <Text textStyle="body-2" color="base.content.default">
            {`Urgent`}
          </Text>
        </HStack>
      )}
    </>
  )
}
