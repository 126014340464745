import { Button, Flex } from '@chakra-ui/react'
import { Banner } from '@opengovsg/design-system-react'

import { AcknowledgeForm } from './AcknowledgeForm'
import { useZoomState, ZoomButtonGroup } from './ZoomButtonGroup'

import { HiddenPrintLayout } from '@/components/HiddenPrintLayout'
import { PublicReferralViewFactory } from '@/features/public-referral/PublicReferralViewFactory'
import { ReferralDto } from '~shared/dtos'

export const Acknowledge = ({ referral }: { referral: ReferralDto }) => {
  const { zoom, onZoomOut, onZoomIn } = useZoomState()

  return (
    <HiddenPrintLayout
      printOnlyChildren={
        <Flex
          w="fit-content"
          h="fit-content"
          bg="white"
          p="1.5rem"
          mx="auto"
          align="stretch"
        >
          <PublicReferralViewFactory referral={referral} />
        </Flex>
      }
    >
      <Banner>
        {`Please review the patient's referral form, then accept or reject the
          referral.`}
      </Banner>
      <Flex h="calc(100vh - 116px)" overflow="hidden">
        <Flex
          h="full"
          flex="1"
          bg="base.canvas.backdrop"
          overflow="auto"
          p="2rem"
        >
          <Flex
            w="fit-content"
            h="fit-content"
            bg="white"
            p="1.5rem"
            mx="auto"
            align="stretch"
            style={{ zoom: `${zoom}%` }}
          >
            <PublicReferralViewFactory referral={referral} />
          </Flex>
          <Flex
            boxShadow="0px -1px 6px rgba(0, 0, 0, 0.15)"
            padding="1rem"
            right={0}
            left={0}
            bottom={0}
            position="fixed"
            zIndex="sticky"
          >
            <ZoomButtonGroup
              onZoomOut={onZoomOut}
              onZoomIn={onZoomIn}
              mx="auto"
            />
            {/* 20 rem is flexible - but is greater than buttongroup's innate width. 
            This prevents zoom buttons from shifting when submit button widths change */}
            <Button
              alignSelf="end"
              variant="outline"
              onClick={() => window.print()}
            >
              Save as PDF
            </Button>
          </Flex>
        </Flex>
        <Flex w="400px" overflowY="auto" p="1rem" pb="2rem">
          <AcknowledgeForm referral={referral} w="full" alignItems="stretch" />
        </Flex>
      </Flex>
    </HiddenPrintLayout>
  )
}
