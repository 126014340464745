import { Text, TextProps, Tooltip, useClipboard } from '@chakra-ui/react'

export const CopyableText = ({
  text,
  ...rest
}: { text: string } & TextProps) => {
  const { onCopy, hasCopied } = useClipboard(text)
  return (
    <Tooltip
      label={hasCopied ? 'Copied' : `Click to copy `}
      closeOnClick={false}
      placement="top"
      hasArrow
    >
      <Text
        cursor="pointer"
        whiteSpace="pre-wrap"
        {...rest}
        onClick={onCopy}
        maxW="100%" // Ensure text stays within parent container
      >
        {text}
      </Text>
    </Tooltip>
  )
}
