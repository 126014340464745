export type EaFormFields = {
  decision: EaDecisionEnum
  date?: Date
  time: string
  noteContent?: string
}

export enum EaDecisionEnum {
  ACCEPT = 'accept',
  COMMENT = 'comment',
  REJECT = 'reject',
}

export const requiredRule = {
  required: 'This field is required.',
}
