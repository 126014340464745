import { Text, TextProps } from '@chakra-ui/react'

export const Title = (props: TextProps) => {
  return (
    <Text
      textStyle="subhead-3"
      textColor="base.content.medium"
      textTransform="uppercase"
      {...props}
    />
  )
}
