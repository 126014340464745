import { BiIdCard, BiMale, BiPhone } from 'react-icons/bi'
import { Flex, HStack, Text, Tooltip, useClipboard } from '@chakra-ui/react'

import { PublicReferralDto } from '~shared/dtos'

export const PatientDetails = ({
  referral,
}: {
  referral: PublicReferralDto
}) => {
  const { onCopy: onCopyName, hasCopied: hasCopiedName } = useClipboard(
    referral.patient.name,
  )

  const { onCopy: onCopyUin, hasCopied: hasCopiedUin } = useClipboard(
    referral.patient.uin,
  )

  const { onCopy: onCopyPhone, hasCopied: hasCopiedPhone } = useClipboard(
    referral.patient.phoneNumber ?? '',
  )

  return (
    <Flex alignItems="flex-start" gap={2} flexDir="column">
      <Text
        textStyle="body-2"
        fontWeight="600"
        letterSpacing="1px"
        color="base.content.default"
      >
        PATIENT DETAILS
      </Text>
      <HStack w="full" color="base.divider.strong">
        <BiMale fontSize="1.25rem" />
        <Tooltip
          label={hasCopiedName ? 'Copied' : 'Click to copy name'}
          closeOnClick={false}
        >
          <Text
            cursor="pointer"
            onClick={onCopyName}
            textStyle="body-2"
            color="base.content.default"
          >
            {referral.patient.name}
          </Text>
        </Tooltip>
      </HStack>
      <HStack w="full" color="base.divider.strong">
        <BiIdCard fontSize="1.25rem" />
        <Tooltip
          label={hasCopiedUin ? 'Copied' : 'Click to copy UIN'}
          closeOnClick={false}
        >
          <Text
            cursor="pointer"
            onClick={onCopyUin}
            textStyle="body-2"
            color="base.content.default"
          >
            {referral.patient.uin}
          </Text>
        </Tooltip>
      </HStack>
      <HStack w="full" color="base.divider.strong">
        <BiPhone fontSize="1.25rem" />
        <Tooltip
          label={hasCopiedPhone ? 'Copied' : 'Click to copy phone number'}
          closeOnClick={false}
        >
          <Text
            cursor="pointer"
            onClick={onCopyPhone}
            textStyle="body-2"
            color="base.content.default"
          >
            {referral.patient.phoneNumber}
          </Text>
        </Tooltip>
      </HStack>
    </Flex>
  )
}
